import React, { Fragment } from "react";
//import { useStaticQuery, graphql } from "gatsby";
import Fade from "react-reveal/Fade";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Button from "common/components/Button";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";
import Rating from "common/components/Rating";
import GlideCarousel from "common/components/GlideCarousel";
import GlideSlide from "common/components/GlideCarousel/glideSlide";
import { SectionHeader } from "./testimonial.style";
import SectionWrapper, { CarouselWrapper } from "./testimonial.style";
import SmartText from "../../common/components/SmartText";

const Testimonial = ({ allReviews, lang = "He" }) => {
  const [reviews, setReviews] = React.useState(
    allReviews.filter((review) => review.lang.includes(lang))
  );

  const glideOptions = {
    type: "carousel",
    gap: 0,
    autoplay: 5000,
    perView: 2,
    animationDuration: 700,
    breakpoints: {
      991: {
        perView: 1,
      },
    },
  };

  const textLang = {
    topTitle: { He: "ביקורות", En: "Reviews" },
    mainTitle: {
      He: "מטיילים עצמאיים מספרים",
      En: "Self-guided Tour tell on us ",
    },
  };

  return (
    <SectionWrapper id="testimonial">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h3" content={textLang["topTitle"][lang]} />
            <Heading content={textLang["mainTitle"][lang]} />
          </Fade>
        </SectionHeader>

        <CarouselWrapper>
          <Fade up delay={100}>
            <GlideCarousel
              options={glideOptions}
              nextButton={
                <Button
                  icon={<i className="flaticon-next" />}
                  aria-label="Next"
                  variant="fab"
                />
              }
              prevButton={
                <Button
                  icon={<i className="flaticon-left-arrow" />}
                  aria-label="Prev"
                  variant="fab"
                />
              }
            >
              <Fragment>
                {reviews.map((item) => (
                  <GlideSlide key={`testimonial--key${item.id}`}>
                    <div className="reviews-card">
                      {item.title && <Heading as="h3" content={item.title} />}
                      <SmartText text={item.content} length={100} lang={lang} />
                      <div className="card-footer">
                        <div className="image">
                          <Image src={item.imgurl} alt="Client Image" />
                        </div>
                        <div className="reviewer-info">
                          <div className="content">
                            <Heading as="h4" content={item.name} />
                            {item.designation && (
                              <Text content={item.designation} />
                            )}
                          </div>
                          <Rating rating={item.rating} />
                        </div>
                      </div>
                    </div>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          </Fade>
        </CarouselWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Testimonial;
