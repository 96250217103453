import React from "react";
import { navigate } from "gatsby";
import { bitCheck } from "../../common/components/payment";
import spinner from "../../images/Spinner.gif";

import couponImg from "../../images/couponBut.png";

const CheckCoupon = ({
  price,
  setPrice,
  couponPlaceHolder,
  setCouponText,
  couponText,
  t_id,
  source_id,
  lang = "En",
}) => {
  const [userCoupon, setUserCoupon] = React.useState("");
  const [loading, setloading] = React.useState(false);

  const textLang = {
    textMessage: {
      He: "הקופון התקבל בהצלחה, המחיר עודכן",
      En: "We got your coupon, the price updated",
    },
    textFailMessage: {
      He: "הקופון אינו תקין, אנא ודאו כי הקופון נכון",
      En: "The coupon is unavailable",
    },
    textPlaceHolder: {
      He: "זה המקום עבור הקופון שלך",
      En: "This is he place for your coupon",
    },
  };

  const checkCoupon = () => {
    if (!userCoupon) {
      return null;
    }
    setloading(true);
    fetch(
      `https://europe-west1-tours-app-1579553856346.cloudfunctions.net/CheckCoupon?couponID=${userCoupon}`,
      {
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          const new_price = price - (data.discount / 100) * price;
          setCouponText(
            `${textLang["textMessage"][lang]} ${price} -> ${new_price}`
          );
          if (data.discount === 100) {
            const sumBitCalc = bitCheck(t_id);
            setPrice(0, userCoupon);
            navigate(
              `/returnPayment/?tID=${t_id}&coupon=${userCoupon}&bitCheck=${sumBitCalc}&source=${source_id}`
            );
          } else {
            setPrice(new_price, userCoupon);
          }
        });
      } else {
        setCouponText(textLang["textFailMessage"][lang]);
      }
      setloading(false);
    });
  };

  return (
    <div
      className="couponWraper"
      style={{ /* position: "fixed" */ marginTop: "25px" }}
    >
      <div
        className="couponContainer"
        style={{
          height: "54px",
          backgroundColor: "#fffff",
          display: "flex",
          flexDirection: "row",
          marginTop: "100px",
          direction: lang === "He" ? "rtl" : "ltr",
        }}
      >
        <input
          onChange={(e) => setUserCoupon(e.target.value)}
          type="text"
          placeholder={textLang["textPlaceHolder"][lang]}
          value={userCoupon}
          /* className="couponInput" */
          style={{
            width: "80%",
            maxWidth: "350px",
            height: "54px",
            fontSize: "15px",
            fontWeight: "700",
          }}
        />
        {!loading ? (
          <button>
            <img
              src={couponImg}
              alt="Check coupon"
              style={{
                height: "48px",
                transform: lang === "He" && "rotate(180deg)",
              }}
              onClick={() => checkCoupon()}
            />
          </button>
        ) : (
          <img
            src={spinner}
            style={{
              height: "48px",
            }}
            alt="loading icon"
          />
        )}
        {couponText && <div>{couponText} </div>}
      </div>
    </div>
  );
};

export default CheckCoupon;
